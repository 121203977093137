// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import filters from "./filters";
import modal from "./modal";
import dateFilter from "./dateFilter";


const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  filters,
  modal,
  dateFilter
});

export default rootReducer;
