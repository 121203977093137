import { toast } from "react-toastify";

export const versionControl = () => {
  localStorage.clear();
  return (
    toast.error(
      "We have added new features. After reload page, Please login again to access them."
    ),
    window.location.reload(true)
  );
};
