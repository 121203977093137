import React from "react";
import { Progress } from "reactstrap";
import Lottie from "react-lottie";
import done from "../Icons/done-animation.json";
import failed from "../Icons/failed-animation.json";
import initiated from "../Icons/initiated-animation.json";
import progress from "../Icons/inprocess-animation.json";
import { useSelector } from "react-redux";

const animationList = {
  done: done,
  failed: failed,
  initiated: initiated,
  progress: progress,
};

const animationWithConfig = (status) => {
  return {
    loop: true,
    autoplay: true,
    animationData: animationList[status],
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};

function TicketMigrationModal() {
  const { status, totalTicketsToMigrateDone, totalTicketsToMigrate } =
    useSelector((state) => state.modal);

  const TICKET_MIGRATION_PERCENTAGE =
    (totalTicketsToMigrateDone / totalTicketsToMigrate) * 100;

  return (
    <div>
      <Lottie options={animationWithConfig(status)} height="80%" width="80%" />
      <h3 className={`text-center text-primary`}>{status.toUpperCase()}</h3>
      {/* {status != "initiated" ? ( */}
      <div style={{ padding: "25px" }}>
        <Progress
          animated={true}
          bar
          color={status === "failed" ? "danger" : "success"}
          value={`${
            `${TICKET_MIGRATION_PERCENTAGE}` === "NaN"
              ? 0
              : TICKET_MIGRATION_PERCENTAGE
          }`}
        >
          {status !== "failed"
            ? `${
                `${TICKET_MIGRATION_PERCENTAGE}` === "NaN"
                  ? 0
                  : TICKET_MIGRATION_PERCENTAGE
              } %`
            : "Failed"}
        </Progress>
      </div>
      {/* // ) : ( // "" // )} */}
    </div>
  );
}

export default TicketMigrationModal;
