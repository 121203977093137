import React, { Fragment } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { observer } from "mobx-react-lite";
import { toggleModalPop } from "@store/actions/modal";
import TicketMigrationModal from "../TicketsMigration/List/components/TicketMigrationModal";
import WeeklyReportGenerateModal from "@src/views/Components/Dashboard/reports/WeeklyReportGenerateModel";
import "@styles/base/plugins/extensions/ext-component-toastr.scss";

const AppModal = observer(() => {
  const { show, modalName, title } = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  const toggleModal = () => {
    dispatch(toggleModalPop());
  };

  return (
    <Fragment>
      <Modal isOpen={show} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal} className="align-items-center">
          {title}
        </ModalHeader>
        {modalName === "MIGRATION_MODAL" ? (
          <TicketMigrationModal />
        ) : modalName === "REPORTS_MODAL" ? (
          <WeeklyReportGenerateModal />
        ) : (
          ""
        )}
      </Modal>
    </Fragment>
  );
});

export default AppModal;
