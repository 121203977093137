import React from "react";
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import { customStyles } from "./Objects/Object";
import { FormattedMessage } from "react-intl";

function CustomSelect({
  name,
  options,
  value,
  handleChange,
  icon,
  customClass,
  isDisabled = false,
  isLoading = false,
  isMulti = true,
  optionLabel = "name",
  optionValue = "_id",
  ...props
}) {
  const id = props?.id || name;
  const label = props.label || "No Label";
  const selectClassPrefix = props?.selectClassPrefix || "";

  const [inputValue, setInputValue] = React.useState("");

  const filteredOptions = () => {
    const selectedNames = new Set(value.map((v) => v.name.toLowerCase()));
    const lowerInputValue = inputValue.toLowerCase();

    return options.filter((option) => {
      const optionNameLower = option.name?.toLowerCase();
      const matchesInput = optionNameLower.includes(lowerInputValue);
      const isSelected = selectedNames.has(optionNameLower);

      return matchesInput && !isSelected;
    });
  };

  const handleSelection = (e) => {
    if (isMulti) {
      const isSelectAll = e.some((item) => item?._id === "Select All");
      if (isSelectAll) {
        e = e.filter((item) => item?._id !== "Select All");

        e = [...e, ...filteredOptions()];
      }
    }
    handleChange(name, e);
  };

  const handleInputChange = (input) => {
    setInputValue(input);
    return input;
  };

  const customFilter = React.useCallback((candidate, input) => {
    if (!input) {
      // If there's no input, show all options including "Select All"
      return true;
    }
    if (candidate.label === "Select All") {
      return true;
    }
    return candidate.label?.toLowerCase().includes(input.toLowerCase());
  }, []);

  return (
    <div className={customClass}>
      <div id={id}>{icon}</div>
      {props?.iconTooltip && (
        <UncontrolledTooltip placement="top" target={id}>
          {props.iconTooltip}
        </UncontrolledTooltip>
      )}
      <FormGroup noMargin={true} className="w-100 pl-1 form-group">
        <Label className="mb-0">
          <FormattedMessage id={label} defaultMessage={label} />
          {props?.isRequired && <strong className="text-danger">*</strong>}
        </Label>
        <Select
          getOptionValue={(opt) => opt[optionValue]}
          getOptionLabel={(opt) => opt[optionLabel]}
          options={options}
          isDisabled={isDisabled}
          isLoading={isLoading}
          value={value}
          styles={customStyles}
          classNamePrefix={selectClassPrefix}
          isMulti={isMulti}
          onChange={handleSelection}
          filterOption={customFilter}
          onInputChange={handleInputChange}
        />
        {props?.isRequired && (
          <>
            <Input hidden invalid={props?.invalid} />
            <FormFeedback className="ml-1" invalid={props?.invalid}>
              <FormattedMessage
                id={`This field is required!`}
                defaultMessage={`This field is required!`}
              />
            </FormFeedback>
          </>
        )}
      </FormGroup>
    </div>
  );
}

export default CustomSelect;
