// ** ThemeConfig Import

// ** Initial State
const initialState = {
  show: false,
  type: "",
  modalName: "",
  title: "",
  status: "",
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_MODAL":
      return { ...state, show: !state.show };
    case "CLOSE_MODAL":
      return { ...state, show: false };
    case "HANDLE_MODAL_TYPE":
      return { ...state, type: action.value };
    case "MODAL_NAME":
      return {
        ...state,
        show: true,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default modalReducer;
