import React from "react";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";

class CustomButton extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <label>
        <Button
          className={this.props.className}
          color={this.props.color}
          onClick={this.props.onClick}
          size={this.props.size}
          type={this.props.type}
          outline={this.props.outLine}
          disabled={this.props.submitting || this.props.disabled}
        >
          <FormattedMessage
            id={this.props.text}
            defaultMessage={this.props.text}
          />
        </Button>
      </label>
    );
  }
}
export default CustomButton;
