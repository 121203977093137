import { authHeader, golangHeader } from "./authHeader";
import { baseUrl } from "./ApiCall";
import axios from "axios";
import { handleLogin } from "@store/actions/auth";
import { versionControl } from "./VersionControl";
import { basePathApiGolang } from "./basePathApi";

export const SC = {
  getCall,
  postCall,
  putCall,
  deleteCall,
  postCallWithOrg,
  postCallLoginAdmin,
  postCallWithoutAuth,
  getCallWithOrgId,
  getCallWithParams,
  putCallWithOrg,
  postAttachment,
  getCallWithId,
  getCallWithoutAuth,
  deleteCallWithPost,
  postCallWithOutRole,
  getCallWithTeam,
  postCallWithRole,
  postCall_golang,
  getCall_golang,
};

// Add a response interceptor
// axios.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {

//     if (error.response.status === 500 ) {
//      navigate("/internal-server-error")
//     }
//     return Promise.reject(error);
//   }
// )

function getCall(url, queryParams) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  let params = {
    version: process.env.REACT_APP_VERSION,
    role,
    organization_id: id,
    ...(queryParams || {}),
  };
  let urlEmbeddedParams = url.split("?");
  if (urlEmbeddedParams.length > 1) {
    urlEmbeddedParams = urlEmbeddedParams[1];
    urlEmbeddedParams = urlEmbeddedParams.split("&");
    urlEmbeddedParams.map((item) => {
      const s = item.split("=");
      if (s?.length > 1) params[s[0]] = s[1];
    });
  }

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    params,
  };

  return axios
    .get(
      baseUrl + url,
      // `${url.includes("?") ? "&" + queryParams : "?" + queryParams}`,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
function postCallWithRole(url, Id) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  var queryParam =
    "version=" +
    process.env.REACT_APP_VERSION +
    "&role=" +
    role +
    "&organization_id=" +
    id;
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    Id,
  };

  return axios
    .post(
      baseUrl +
        url +
        `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`,
      Id,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
function getCallWithOrgId(url) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  var queryParam =
    "version=" +
    process.env.REACT_APP_VERSION +
    "&role=" +
    role +
    "&organization_id=" +
    id;
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return axios
    .get(
      baseUrl +
        url +
        `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}

function getCallWithParams(url, params = {}) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  params = {
    ...params,
    version: process.env.REACT_APP_VERSION,
    organization_id: localStorage.getItem("orgId"),
  };
  params = Object.entries(params)
    .map(([key, value]) => `${[key]}=${value}`)
    .join("&");
  return axios
    .get(baseUrl + url + "?" + params, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
function postCall(url, data, callbackProgressUpload = null, source) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  var queryParam =
    "version=" +
    process.env.REACT_APP_VERSION +
    "&role=" +
    role +
    "&organization_id=" +
    id;
  Date.prototype.toJSON = function () {
    // return moment(this).format();
  };
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json", // Set content type to JSON
    },
    body: JSON.stringify(data),
    onUploadProgress: function (progressEvent) {
      // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };

  if (source) {
    requestOptions.cancelToken = source.token;
  }
  return axios
    .post(
      baseUrl +
        url +
        `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`,
      data,
      !url.includes("get-all-team") && requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}

function postCallWithOrg(url, data, callbackProgressUpload = null, source) {
  let role = localStorage.getItem("currentRole");
  Date.prototype.toJSON = function () {
    // return moment(this).format();
  };
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json", // Set content type to JSON
    },
    body: JSON.stringify(data),
    onUploadProgress: function (progressEvent) {
      // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };

  if (source) {
    requestOptions.cancelToken = source.token;
  }
  return axios
    .post(
      baseUrl +
        url +
        "&version=" +
        process.env.REACT_APP_VERSION +
        "&role=" +
        role,

      data,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
function postAttachment(url, data, callbackProgressUpload = null, source) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
    onUploadProgress: function (progressEvent) {
      // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };

  if (source) {
    requestOptions.cancelToken = source.token;
  }

  return axios
    .post(url, data, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}

function putCallWithOrg(url, data) {
  let role = localStorage.getItem("currentRole");
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return axios
    .put(
      baseUrl +
        url +
        "&version=" +
        process.env.REACT_APP_VERSION +
        "&role=" +
        role,

      data,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
function putCall(url, data) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  var queryParam =
    "version=" +
    process.env.REACT_APP_VERSION +
    "&role=" +
    role +
    "&organization_id=" +
    id;
  const requestOptions = {
    method: "PUT",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  // add condition on url by hassnain
  const updateUrl = url.includes("update-organization")
    ? baseUrl +
      url +
      id +
      `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`
    : baseUrl +
      url +
      `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`;
  //
  return axios
    .put(
      baseUrl +
        url +
        `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`,
      data,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
function deleteCall(url) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  var queryParam =
    "version=" +
    process.env.REACT_APP_VERSION +
    "&role=" +
    role +
    "&organization_id=" +
    id;
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return axios
    .delete(
      baseUrl +
        url +
        `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`,

      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
function deleteCallWithPost(url, ids, callbackProgressUpload = null, source) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  var queryParam =
    "version=" +
    process.env.REACT_APP_VERSION +
    "&role=" +
    role +
    "&organization_id=" +
    id;
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(ids),
    onUploadProgress: function (progressEvent) {
      // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };

  if (source) {
    requestOptions.cancelToken = source.token;
  }
  return axios
    .post(
      baseUrl +
        url +
        `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`,

      ids,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}
function postCallLoginAdmin(url, data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return axios
    .post(
      baseUrl + url + "?version=" + process.env.REACT_APP_VERSION,
      data,
      requestOptions
    )
    .then((response) => {
      if (response.data) {
        handleLogin(response.data);
      }
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        // versionControl();
      }
      return Promise.reject(error);
    });
}

function getCallWithoutAuth(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .get(
      baseUrl +
        url +
        `${url.includes("?") ? "&version=" : "?version="}` +
        process.env.REACT_APP_VERSION,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
function postCallWithoutAuth(url, data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return axios
    .post(
      baseUrl + url + "?version=" + process.env.REACT_APP_VERSION,
      data,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}

//get data with  id
function getCallWithId(url, id) {
  let role = localStorage.getItem("currentRole");
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return axios
    .get(
      baseUrl +
        url +
        "/" +
        id +
        "?version=" +
        process.env.REACT_APP_VERSION +
        "&role=" +
        role +
        "&organization_id=" +
        id,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
//get data with  team id
function getCallWithTeam(url) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  var queryParam =
    "version=" +
    process.env.REACT_APP_VERSION +
    "&role=" +
    role +
    "&organization_id=" +
    id;
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return axios
    .get(
      baseUrl +
        url +
        `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
function postCallWithOutRole(url, data, callbackProgressUpload = null, source) {
  Date.prototype.toJSON = function () {
    // return moment(this).format();
  };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
    onUploadProgress: function (progressEvent) {
      // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };

  if (source) {
    requestOptions.cancelToken = source.token;
  }
  return axios
    .post(
      baseUrl +
        url +
        `${url.includes("?") ? "&version=" : "?version="}` +
        process.env.REACT_APP_VERSION,
      data,
      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
//  GOLANG API SERVER CALLS

function postCall_golang(
  url,
  data,
  page,
  per_page,
  callbackProgressUpload = null,
  source,
  sorting
) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  var queryParam =
    "version=" +
    process.env.REACT_APP_VERSION +
    "&role=" +
    role +
    "&organization_id=" +
    id;
  Date.prototype.toJSON = function () {
    // return moment(this).format();
  };
  const requestOptions = {
    headers: golangHeader(),
    body: JSON.stringify(data),
    onUploadProgress: function (progressEvent) {
      // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };

  if (source) {
    requestOptions.cancelToken = source.token;
  }
  let endPoint = url;
  if (page) {
    endPoint = endPoint + "/" + id + "?page=" + page + "&perPage=" + per_page;
  }
  if (sorting) {
    endPoint = endPoint + "&sort=" + `${sorting.id},desc:${sorting.desc}`;
  }
  return axios
    .post(basePathApiGolang + endPoint, data, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}

function getCall_golang(url, page) {
  let id = localStorage.getItem("orgId");
  let role = localStorage.getItem("currentRole");
  var queryParam =
    "version=" +
    process.env.REACT_APP_VERSION +
    "&validateStatus=" +
    role +
    "&organization_id=" +
    id;
  const requestOptions = {
    method: "GET",
    headers: golangHeader(),
  };

  return axios
    .get(
      basePathApiGolang + url + "?role=" + role + "&" + "organization_id=" + id,
      // `${url.includes("?") ? "&" + queryParam : "?" + queryParam}`,

      requestOptions
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (error.response?.data?.flag === "VERSION_NOT_MATCHED") {
        versionControl();
      }
      return Promise.reject(error);
    });
}
