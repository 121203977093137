/**
 * SET TICKETS FILTER DISPATCHER FOR REDUX
 * @param {Object} payload
 * @returns
 */
export const setTicketsFilterAction = (payload) => (dispatch) =>
  dispatch({
    type: "SET_TICKETS_FILTER",
    payload: payload,
  });

/**
 * RESET ALL TICKETS FILTER DISPATCHER FOR REDUX
 */
export const resetAllTicketsFilterAction = () => (dispatch) =>
  dispatch({
    type: "RESET_ALL_TICKETS_FILTER",
  });
