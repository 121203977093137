import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "./assets/MOH-logo.png";
import bgImg from "./assets/pdf-bg.png";
import PCOCLogo from "./assets/pcoc-logo.png";
import bgImgGreen from "./assets/pdf-bg-green.png";
import clockIcon from "./assets/clock.png";
import LatoBlack from "./assets/fonts/Lato/Lato-Black.ttf";
import Source_Serif from "./assets/fonts/Source_Serif_4/SourceSerif4-Italic-VariableFont_opsz,wght.ttf";
import LatoRegular from "./assets/fonts/Lato/Lato-Regular.ttf";
import LatoLight from "./assets/fonts/Lato/Lato-Light.ttf";
import LatoItalic from "./assets/fonts/Lato/Lato-Italic.ttf";
import LatoBold from "./assets/fonts/Lato/Lato-Bold.ttf";
import Noto_Sans_Arabic from "./assets/fonts/Noto_Sans_Arabic/NotoSansArabic-VariableFont_wdth,wght.ttf";
// Register custom fonts
Font.register({
  family: "Lato",
  fonts: [
    { src: LatoLight, fontWeight: "light" },
    { src: LatoItalic, fontStyle: "italic" },
    { src: LatoRegular, fontWeight: "normal" },
    { src: LatoBlack, fontWeight: "heavy" },
    { src: LatoBold, fontWeight: "bold" },
  ],
});
Font.register({
  family: "Source Serif 4",
  fonts: [{ src: Source_Serif, fontStyle: "italic" }],
});

Font.register({
  family: "Noto Naskh Arabic",
  src: Noto_Sans_Arabic,
});
const colors = {
  primaryColor: "#008755",
  secondaryColor: "#9b945f",
  secondaryDarkColor: "#806000",
};
// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  pageLayout: {
    position: "relative",
    width: 1860, // Full HD widescreen size
    height: 1020,
  },
  backgroundImage: {
    position: "absolute",
    width: 962,
    height: 224,
    zIndex: -1, // Send it to the back
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  logo: {
    width: 200,
    height: 200,
    marginLeft: 100,
    marginTop: 100,
  },
  header: {
    textAlign: "center",
    marginTop: 150,
  },
  title: {
    fontSize: 80,
    fontFamily: "Noto Naskh Arabic",
    color: colors.primaryColor,
  },
  date: {
    marginBottom: 30,
    fontFamily: "Lato",
    fontWeight: "heavy",
    fontSize: 30,
    color: colors.secondaryColor,
  },
  subTitle: {
    fontFamily: "Lato",
    fontWeight: "normal",
    fontSize: 35,
    color: colors.secondaryDarkColor,
  },
  hr: {
    marginTop: "auto",
    marginBottom: 10,
    height: 2,
    width: "100%",
    backgroundColor: colors.secondaryColor,
  },
  lastPage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primaryColor,
  },
  tablePageHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 10,
  },
  tablePageHeaderLogo: {
    width: 250,
    height: 90,
  },
  tablePageHeaderTitleContainer: {
    flexGrow: 1,
    paddingVertical: 10,
    minWidth: 700,
    marginLeft: 100,
    borderWidth: 1,
    borderLeft: "none",
    borderRight: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: colors.secondaryColor,
  },
  tablePageHeaderTitleText: {
    color: colors.secondaryColor,
    fontSize: 35,
    fontFamily: "Source Serif 4",
    fontStyle: "italic",
  },
  tablePageHeaderDateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    marginLeft: 40,
  },
  dateIconContainer: {
    width: 30,
    height: 30,
  },
  dateIcon: {
    width: "100%",
    height: "100%",
  },
  dateText: {
    fontFamily: "Lato",
    fontWeight: "normal",
    marginLeft: 20,
    fontSize: 30,
    color: colors.primaryColor,
  },
  table: {
    display: "table",
    width: "100%",
    margin: "auto",
  },
  tableHeader: {
    height: 50,
    fontSize: 20,
    color: "white",
    backgroundColor: colors.primaryColor,
    fontFamily: "Lato",
    fontWeight: "heavy",
    borderBottom: "1px solid white",
    textTransform: "capitalize",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableRowOdd: {
    backgroundColor: "#cbd9d1", // Light gray background for odd rows
  },
  tableRowEven: {
    backgroundColor: "#e7ede9", // White background for even rows
  },
  tableCol: {
    width: "100%",
    minHeight: 30,
    padding: 5,
    border: "none",
    borderRightWidth: 1,
    borderColor: "white",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCell: {
    textAlign: "center",
  },
  primaryCol: {
    fontFamily: "Lato",
    fontStyle: "italic",
    fontSize: 15,
    color: "white",
    backgroundColor: colors.primaryColor,
    borderBottom: "none",
    textTransform: "capitalize",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  tableLastRow: {
    fontFamily: "Lato",
    borderTopWidth: 1,
    borderColor: "white",
    fontStyle: "normal",
  },
  noRecordsContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
  },
  noRecords: {
    fontSize: 60,
    color: colors.primaryColor,
  },
  teamContainer: {
    borderRadius: 10,
    borderWidth: 2,
    borderColor: colors.secondaryDarkColor,
    width: 500,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  teamText: {
    fontFamily: "Noto Naskh Arabic",
    color: colors.secondaryDarkColor,
    fontSize: 30,
  },
  legends: {
    marginTop: "auto",
    marginBottom: 10,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  legend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "flex-start",
    marginRight: 20,
  },
  legendBox: {
    width: 30,
    height: 20,
  },
  legendText: {
    marginLeft: 10,
    fontSize: 18,
    color: colors.primaryColor,
  },
  footer: {
    textAlign: "center",
    marginTop: 20,
    color: "#008755",
    fontSize: 30,
  },
});

const PowerPointWidescreenSize = [1920, 1080];
const teams = [
  "تذاكر موعد",
  "تذاكر مستعد",
  "تذاكر العيادات المتنقلة",
  "تذاكر TBC",
  "تذاكر تجربة المريض",
  "تذاكر رقيم",
];
const title = "تذاكر مركز عمليات الصحة العامة";

const cellBgColor = (data) => {
  let color =
    data?.overdue === data?.overdueLastWeek
      ? "yellow"
      : data?.overdue > data?.overdueLastWeek
      ? "red"
      : "green";
  return color;
};

// Create Document Component
const PHOCTicketingReports = ({ reports = [], date }) => (
  <Document>
    {/* // INTRO */}
    <Page size={PowerPointWidescreenSize} style={styles.page}>
      <View style={{ ...styles.pageLayout }}>
        <Image
          style={{ ...styles.backgroundImage, top: 0, right: 0 }}
          src={bgImg}
        />
        <View style={styles.contentWrapper}>
          <Image style={styles.logo} src={logo} />
          <View style={styles.header}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.date}>{date}</Text>
          </View>
          <View style={styles.hr} />
        </View>
      </View>
    </Page>
    {/* // TABLE PAGE */}
    {reports.map((item, index) => {
      return (
        <Page key={index} size={PowerPointWidescreenSize} style={styles.page}>
          <View style={{ ...styles.pageLayout }}>
            <Image
              style={{ ...styles.backgroundImage, top: 0, right: 0 }}
              src={bgImg}
            />
            <View style={styles.contentWrapper}>
              <View style={styles.tablePageHeader}>
                <div style={styles.tablePageHeaderLogo} />
                {/* <Image style={styles.tablePageHeaderLogo} src={PCOCLogo} /> */}
                <View style={styles.tablePageHeaderTitleContainer}>
                  <Text style={styles.tablePageHeaderTitleText}>
                    {item.title}
                  </Text>
                </View>
                <View style={styles.tablePageHeaderDateContainer}>
                  <View style={styles.dateIconContainer}>
                    <Image style={styles.dateIcon} src={clockIcon} />
                  </View>
                  <Text style={styles.dateText}>{date}</Text>
                </View>
              </View>
              {/* TABLE */}

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  {item.columns.map((column, colIndex) => {
                    return (
                      <Text
                        key={colIndex}
                        style={{
                          ...styles.tableCol,
                          ...styles.tableHeader,
                        }}
                      >
                        {column.title}
                      </Text>
                    );
                  })}
                </View>
                {/* ROWS */}
                {(item?.data?.length === 0 && (
                  <View style={styles.noRecordsContainer}>
                    <Text style={styles.noRecords}>No Data available</Text>
                  </View>
                )) ||
                  item?.data?.map((rowData, rowDataIndex) => {
                    const isLastRow = rowDataIndex + 1 === item?.data?.length;
                    return (
                      <View
                        key={rowDataIndex}
                        style={{
                          ...styles.tableRow,
                          ...(rowDataIndex % 2 === 0
                            ? styles.tableRowEven
                            : styles.tableRowOdd),
                          ...(isLastRow ? styles.tableLastRow : {}),
                        }}
                      >
                        {item.columns.map((colItem, colItemIndex) => {
                          const bgColor = cellBgColor(rowData);
                          const value = rowData?.[colItem?.key] || 0;
                          return (
                            <View
                              key={colItemIndex}
                              style={{
                                ...styles.tableCol,
                                ...(colItemIndex === 0
                                  ? styles.primaryCol
                                  : {}),
                                ...(colItem?.key === "overdue" && value > 0
                                  ? { backgroundColor: bgColor }
                                  : {}),
                              }}
                            >
                              <Text>{value}</Text>
                            </View>
                          );
                        })}
                      </View>
                    );
                  })}
              </View>

              <View style={styles.legends}>
                <View style={styles.legend}>
                  <View
                    style={{ ...styles.legendBox, backgroundColor: "yellow" }}
                  />
                  <Text style={styles.legendText}>No Progress</Text>
                </View>
                <View style={styles.legend}>
                  <View
                    style={{ ...styles.legendBox, backgroundColor: "green" }}
                  />
                  <Text style={styles.legendText}>Improving</Text>
                </View>
                <View style={styles.legend}>
                  <View
                    style={{ ...styles.legendBox, backgroundColor: "red" }}
                  />
                  <Text style={styles.legendText}>Increasing</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      );
    })}
    {/* // THANKS */}

    <Page size={PowerPointWidescreenSize} style={styles.page}>
      <View style={{ ...styles.pageLayout, ...styles.lastPage }}>
        <Text style={{ ...styles.title, color: "white" }}>شكرا</Text>
        <Image
          style={{ ...styles.backgroundImage, bottom: 0, left: 0 }}
          src={bgImgGreen}
        />
      </View>
    </Page>
  </Document>
);

export default PHOCTicketingReports;
