// ** Handles Layout Content Width (full / boxed)
export const toggleModalPop = () => (dispatch) => {
  dispatch({ type: "TOGGLE_MODAL" });
};
export const closeModalPop = () => (dispatch) => {
  dispatch({ type: "CLOSE_MODAL" });
};

// ** Handles Menu Collapsed State (Bool)
export const handleModalType = (value) => (dispatch) =>
  dispatch({ type: "HANDLE_MODAL_TYPE", value });

export const updateModalName = (payload) => (dispatch) => {
  dispatch({ type: "MODAL_NAME", payload });
};
