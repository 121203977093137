// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig";

// ** Initial State
const initialState = {
    start:'',
    end:''
};

const dateFilterReducer = (state = initialState, action) => {
          
    return 'ad';
    // return {...state,start}
    //   switch (action.type) {
//     case "TOGGLE_MODAL":
//       return { ...state, show: !state.show };
//     case "HANDLE_MODAL_TYPE":
//       return { ...state, type: action.value };
//     case "MODAL_NAME":
//       console.log(action, "from cases");
//       return {
//         ...state,
//         modalName: action.value,
//         show: true,
//         status: action.status,
//       };

//     default:
//       return state;
//   }
};

export default dateFilterReducer;
